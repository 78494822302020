<template>
    <div class="container-fluid estproForm">
        <div v-show="showLoading" class="loading"><Loader></Loader></div>
        <div class="lockStartNumber"
            v-show="open_number_estimate"
            v-bind:class="{ show: open_number_estimate }"
        >
            <startNumber
                v-show="open_number_estimate"
                @setChildEmitStartNumber="getParentEmitStartNumber($event)"
                :updateNum="updateStartNumeber"
            ></startNumber>
        </div>
        <div class="card formEst">
            <div class="card-body body-form">
                <div class="row" id="btnTop">
                    <div class="col-4 text-left">
                        <h4 class="card-title">
                            {{ form.titlePage }} #
                            <span v-if="form.numdoc!=null">{{ form.numdoc }}</span>
                            <span v-else>0000</span>
                        </h4>
                    </div>
                    <div class="col-8">
                        <div class="botonera text-right">
                                <button type="button"
                                    class="btn btn-success btn-sm"
                                    @click="saveEstimates"
                                    :disabled="saveDisabled"
                                >
                                    <i class="fas fa-save"></i> SAVE
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secundary btn-sm"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="$router.push({ name: rutaPrincipal })"
                                >
                                    <i class="fas fa-times"></i> CANCEL
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="deleteEstimate(form.idEst)"
                                    v-if="form.actionNname == 'update'"
                                >
                                    <i class="fas fa-trash-alt"></i> DELETE
                                </button>
                        </div>
                    </div>
                </div>
                <div class="row" id="customer_estInfo_date_status">
                    <selectCustomer
                        v-if="loadEstByID"
                        :controlLoad="loadEstByID"
                        :propForm="form"
                        @addNewCustomer="popAddCustomer"
                        @sDS="setDateStorage($event)"
                        @loadCustomer="isLoadingCustomer($event)"
                        @updateProps="changeCustomer($event)"
                    />
                    <infoEst
                        v-if="loadEstByID"
                        :controlLoad="loadEstByID"
                        :propdocument_name="form.estimates_optional"
                        :propjob_information="form.job_information"
                        :propdisabledSteps="disabledSteps"
                        @updateProps="updateJobInfo($event)"
                    />
                    <date_status
                        v-if="loadEstByID"
                        :controlLoad="loadEstByID"
                        :propdisabledSteps="disabledSteps"
                        :propForm="form"
                        @updateProps="updateDateStatus($event)"
                    />
                </div>
                <div id="sec-products">
                    <table_ePro
                        v-if="loadEstByID"
                        :controlLoad="loadEstByID"
                        :propdisabledSteps="disabledSteps"
                        :propForm="form"
                        :propItemsprod="itemsprod"
                        :proptaxShow="taxShow"
                        :propgroupShow="groupShow"
                        @addProdct="addNewProducts"
                        @updateProdcts="setProd"
                    />
                </div>
                <div v-if="!disabledSteps.customer" class="row">
                    <customerMessage
                        v-if="loadEstByID"
                        :controlLoad="loadEstByID"
                        :propdisabledSteps="disabledSteps"
                        :propForm="form"
                        @updateProps="setMessage"
                    />
                    <div class="col-7">
                        <resumenTotal
                            v-if="loadEstByID"
                            :controlLoad="loadEstByID"
                            :propdisabledSteps="disabledSteps"
                            :propForm="form"
                            :propTaxesResumen="taxesResumen"
                            :propItemsprod="itemProducts"
                            :proptaxShow="taxShow"
                            :propgroupShow="groupShow"
                            @setAlertError="setAlert"
                            @updateTotales="setTotales"
                        />
                    </div>
                </div>
                <div v-if="!disabledSteps.customer" class="row" id="contrato">
                    <div class="pageSignature col-12">
                        <div class="form-inline row" >
                            <div class="col-12 text-left" style="border-bottom: 1px solid #ccc; margin-bottom:20px;">
                                <span style="display:inline-block; margin-right:10px;">Need a Contract?</span>
                                <div class="custom-control custom-checkbox" style="display:inline-block;">
                                    <input type="checkbox"
                                        class="custom-control-input"
                                        id="customControlInline"
                                        v-model="form.checked"
                                        :disabled="disabledSteps.customer"
                                    />
                                    <label class="custom-control-label"
                                        for="customControlInline"
                                    >Yes</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="form.checked">
                            <needAcontract
                                :propdisabledSteps="disabledSteps"
                                :propForm="form"
                                :propFormSignature="FormSignature"
                                :propSelectCTemplate="selectCTemplate"
                                @updateProp="setContract"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="!disabledSteps.customer" class="row" style="margin-top: 20px;" id="btnDow">
                    <div class="col-4 text-left">
                    </div>
                    <div class="col-8">
                        <div class="botonera text-right">
                                <button type="button"
                                    class="btn btn-success btn-sm"
                                    @click="saveEstimates"
                                    :disabled="saveDisabled"
                                >
                                    <i class="fas fa-save"></i> SAVE
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secundary btn-sm"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="$router.push({ name: rutaPrincipal })"
                                >
                                    <i class="fas fa-times"></i> CANCEL
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="deleteEstimate(form.idEst)"
                                    v-if="form.actionNname == 'update'"
                                >
                                    <i class="fas fa-trash-alt"></i> DELETE
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>
    </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
// import ClickOutside from 'vue-click-outside'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import Loader from '@/core/components/shared/Loader';
import startNumber from '@/apps/billing/components/estpro/startNumber';
import formCustomer from '@/apps/billing/components/formCustomer';
import selectCustomer from '@/apps/billing/components/estpro/secForm/selectCustomer.vue';
import infoEst from '@/apps/billing/components/estpro/secForm/infoEst.vue';
import date_status from '@/apps/billing/components/estpro/secForm/date_status.vue';
import table_ePro from '@/apps/billing/components/estpro/secForm/table_ePro.vue';
import formProducts from '@/apps/billing/components/formProducts'
import customerMessage from '@/apps/billing/components/estpro/secForm/customerMessage.vue';
import resumenTotal from '@/apps/billing/components/estpro/secForm/resumenTotal.vue';
import needAcontract from '@/apps/billing/components/estpro/secForm/needAcontract.vue';
import './form.scss';
export default {
    name: "estproForm",
    components: {
        Loader,
        modalLateral,
        startNumber,
        formCustomer,
        selectCustomer,
        infoEst,
        date_status,
        table_ePro,
        formProducts,
        customerMessage,
        resumenTotal,
        needAcontract,
    },
    data () {
        return {
            showLoading: true,
            m: {
                show: false,
                component: '',
                data: []
            },
            open_number_estimate: false,
            updateStartNumeber: null,
            PlanPremium: false,
            freePlan: false,
            disabledSteps:{
                startNumer: true,
                customer: true,
                nameEst: true,
                jobsInfo: true,
                products: true,
            },
            form: {
                idEst: null,
                titlePage: "Add Estimate",
                btnName: "SAVE",
                actionNname: "create", // update
                customer_id: null,
                numdoc: null,
                customer_name: null,
                customer_email: null,
                customer_address: null,
                billing_address: null,
                estimates_optional: null,
                status: 1,
                accepted_by: null,
                accepted_date: null,
                job_information: null,
                customer_message: null,
                date_created: new Date(),
                subtotal: 0,
                discount_type: 2,
                discount_mount: 0.00,
                discount_total: 0.00,
                discount_name: '',
                total: 0,
                totaltax: null,
                qty: null,
                checked: false,
                signe: false,
                dataItems: [],
            },
            saveDisabled: false,
            loadEstByID: false,
            isLoadCustomer: 'pending',
            validate_load: 0,
            ContractTemLists: [],
            selectCTemplate: {
                title: "Contract Title",
                description: "",
            },
            FormSignature: {
                // nameCustomer: null,
                dateCustomer: new Date(),
                dateCompany: new Date(),
                nameCompany: localStorage.getItem("nomCompany"),
            },
            itemProducts: [],
            itemsprod: [],
            taxesResumen: [],
            taxShow: 0,
            groupShow: 0,
            rutaPrincipal: 'Estimates',
        };
    },
    created () {
        let t = this;
        // t.PlanPremium = (t.$store.getters['core/Org/getPlanId'] > 1);
        t.PlanPremium = true;
        t.freePlan = (t.$store.getters['core/Org/getPlanId'] == 4);
        t.saveDisabled = true; // desactivar bton de save
        t.metstartEstimate().then((start) => {
            let params = Object.keys(t.$route.params);
            t.forOrgRoute();
            if (t.$route.name=='estproFormEdit' && t.$route.params.idest) {
                // editar
                t.form.titlePage = "Edit Estimate";
                t.form.actionNname = "update";
                t.EstimateById(1);
            }
            else if (t.$route.name=='estproFormDuplicate' && t.$route.params.idest) {
                // duplicar documento
                t.form.titlePage = "Duplicate Estimate";
                t.form.actionNname = "duplicate";
                t.EstimateById(2);
            }
            else if (t.$route.name=='estproFormcustomerID' && t.$route.params.idc) {
                // entra desde customer
                t.form.customer_id = t.$route.params.idc;
                // console.log('estproFormcustomerID', t.form.customer_id)
                t.showLoading = false;
                t.loadEstByID = true;
            }
            else{
                // create
                t.showLoading = false;
                t.loadEstByID = true;
            }
        });
    },
    methods: {
        // validar memoria de datos no tan volatil
        forOrgRoute(){
            if(localStorage.getItem('OrgRouteCurrent')==null){
                localStorage.setItem('OrgRouteCurrent', this.$route.name+'/'+ localStorage.getItem('mark_client_data'));
            }
            if(localStorage.getItem('OrgRouteCurrent')!=localStorage.getItem('OrgRoute')){
                // vaciar datos en memoria
                localStorage.setItem('OrgRoute', localStorage.getItem('OrgRouteCurrent'));
                localStorage.removeItem('ProdServiceLists');
                localStorage.removeItem('CustomersList');
            }
        },
        // Consultardo y validando numeracion del documento
        metstartEstimate() {
            let t = this;
            return new Promise((resolve, reject) => {
                if(localStorage.getItem('open_number_estimate')==null){
                    window.billing.get("startestimate/" + window.localuserdata)
                    .then((response) => {
                        // console.log(response.data);
                        if(!response.data || response.data.start_estimate == undefined){
                            // no ha especificado el numero inicial de estimate
                            // abrir modal para config num start
                            t.open_number_estimate = true;
                        }else{
                            // ya ha sido configurado
                            t.open_number_estimate = !response.data.start_estimate;
                            t.disabledSteps.startNumer=false;
                            localStorage.setItem('open_number_estimate', '1');
                        }
                        // console.log('open_number_estimate', t.open_number_estimate)
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    });
                }else{
                    // ya ha sido configurado
                    t.open_number_estimate = false;
                    t.disabledSteps.startNumer=false;
                    resolve(true);
                }
            });
        },
        getParentEmitStartNumber(value){
            this.open_number_estimate=value;
            this.disabledSteps.startNumer=false;
        },
        // validador de carga storage
        validDateUpdateStorage(varDate, varStorage){
            if(localStorage.getItem(varDate)!=null){
                let dateStorage = new Date(localStorage.getItem(varDate, varStorage));
                let dateActual = new Date();
                var fecha1 = moment(dateActual);
                var fecha2 = moment(dateStorage);
                let minutes = fecha1.diff(fecha2, 'minutes');
                if(minutes>5){
                    localStorage.removeItem(varStorage);
                }
            }
        },
        setDateStorage(varDate){
            let fecha = new Date().getFullYear()+'/'+(new Date().getMonth()+1)+'/'+new Date().getDate();
            let hora = new Date().getHours()+':'+new Date().getMinutes()+':0';
            let dateStorage = fecha+' '+hora;
            localStorage.setItem(varDate, dateStorage);
        },
        // save, get, del
        saveEstimates() {
            let t = this;
            t.showLoading = true;
            t.errors = [];
            if (!t.form.customer_id) {
                t.msjAlert('Must select "Customer')
                t.showLoading = false;
                t.saveDisabled = false;
                return false;
            }
            if (t.form.total == 0) {
                // t.errors.push("Total must not be greater than zero");
                t.msjAlert("Total must not be greater than zero")
                t.showLoading = false;
                t.saveDisabled = false;
                return false;
            }
            if (t.form.customer_id && t.errors.length == 0) {
                let sendData = t.createDataSend();
                console.log(t.form.actionNname, sendData)
                if(!t.debuggerData(sendData)){
                    // si hay errores cancelamos la carga
                    t.showLoading = false;
                    t.saveDisabled = false;
                }
                else{
                    if (t.form.actionNname == "create" || t.form.actionNname == "duplicate"){
                        window.billing.post("estimates/add", sendData)
                        .then((response) => {
                            t.showLoading = false;
                            t.saveDisabled = false;
                            t.$notify({
                                group: "noti",
                                title: "Created!",
                                type: "success",
                                text: "Successful registration",
                            });
                            t.$router.push({ name: t.rutaPrincipal });
                        })
                        .catch((error) => {
                            console.log(error);
                            t.showLoading = false;
                            t.saveDisabled = false;
                        });
                    }
                    else if (t.form.actionNname == "update") {
                        // comparamos si hay datos mnodificados
                        if(!t.isChangeData(sendData)){
                            // sino hay modificaciones retornamos a la lista principal
                            t.$router.push({ name: t.rutaPrincipal });
                        }
                        else{
                            // caso contrario enviamos a guardar
                            window.billing.put("estimate/edit/" + t.form.idEst, sendData)
                            .then((response) => {
                                t.showLoading = false;
                                t.saveDisabled = false;
                                // console.log(response.data);
                                t.$notify({
                                    group: "noti",
                                    title: "Updated",
                                    type: "success",
                                    text: "Successful Updated",
                                });
                                t.$router.push({ name: t.rutaPrincipal });
                            })
                            .catch((error) => {
                                console.log(error);
                                t.showLoading = false;
                                t.saveDisabled = false;
                            });
                        }
                    }
                }
            }
        },
        createDataSend(){
            return {
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    socket_id: pusher.connection.socket_id,
                    idEst: this.form.idEst,
                    id: this.form.idEst,
                    numdoc: this.form.numdoc,
                    customer_id: this.form.customer_id,
                    estimate_optional: this.form.estimates_optional,
                    status: this.form.status,
                    accepted_by: this.form.accepted_by,
                    accepted_date: this.form.accepted_date,
                    job_information: this.form.job_information,
                    customer_message: this.form.customer_message,
                    need_contract: this.form.checked,
                    signe: this.form.signe,
                    terms_conditions_title: this.selectCTemplate.title,
                    terms_conditions_desc: this.selectCTemplate.description,
                    signaturecustomer: this.form.customer_name,
                    signaturecustomerdate: this.FormSignature.dateCustomer,
                    signaturecompany: this.FormSignature.nameCompany,
                    signaturecompanydate: this.FormSignature.dateCompany,
                    date_create: this.form.date_created,
                    sub_total: this.form.subtotal,
                    discount_type: this.form.discount_type,
                    discount_mount: this.form.discount_mount,
                    discount_total: this.form.discount_total,
                    discount_name: this.form.discount_name,
                    total: this.form.total,
                    dataItems: this.itemProducts,
                    taxShow: this.taxShow,
                    type_estimate: 'pro',
                    groupShow: this.groupShow,
                };
        },
        debuggerData(sendData){
            const ignore = ["accepted_by", "discount_name", "idEst", "id", "numdoc", "estimate_optional", "accepted_date", "job_information", "customer_message", "terms_conditions_title", "terms_conditions_desc"];
            const errors = Object.keys(sendData).filter(field => {
                let isValid = sendData[field] !== undefined && sendData[field] !== null;
                if(ignore.indexOf(field) > -1){ isValid=true;}
                if (!isValid) {
                    // console.log(`El campo "${field}" no cumple con las reglas de validación.`);
                    this.msjAlert(`El campo "${field}" no cumple con las reglas de validación.`);
                }
                return !isValid;
            });
            return (errors.length === 0)
        },
        isChangeData(sendData) {
            const original = JSON.parse(localStorage.getItem("dataOrigin"));
            if(original){
                const modifiedFields = Object.keys(original).filter(
                    key => original[key] !== sendData[key]
                );
                if (modifiedFields.length > 0) {
                    // console.log("Campos modificados:", modifiedFields);
                    return true;
                    // modifiedFields .forEach(field => {
                    //     console.log(`Campo: ${field}, Valor original: ${original[field]}, Nuevo valor: ${sendData[field]}`);
                    // });
                } else {
                    // console.log("No se modificaron campos.");
                    return false;
                }
            }
            return false;
        },
        EstimateById(state) {
            let t = this;
            t.showLoading = true;
            window.billing
            .get("estimate/" + t.$route.params.idest)
            .then((response) => {
                let dataR = response.data;
                let estItem = dataR.result;
                let estCustomer = dataR.customer;
                t.form.customer_id = estCustomer.id;
                t.form.idEst = estItem.id;
                t.form.numdoc = estItem.numdoc;
                t.form.job_information = estItem.job_information;
                t.form.estimates_optional = estItem.estimate_optional;
                t.form.customer_message = estItem.customer_message;
                t.form.accepted_by = estItem.accepted_by;
                t.form.accepted_date =new Date(estItem.accepted_date);
                t.form.status = estItem.status;
                t.form.date_created = (t.form.actionNname == 'duplicate') ? new Date() : estItem.date_create
                t.inputFecha = (t.form.actionNname == 'duplicate') ? new Date().toISOString().slice(0, 10) : estItem.date_create
                t.form.actionNname = "update"
                t.form.checked = estItem.need_contract == 1;
                t.form.signe = estItem.signe == 1;
                t.form.subtotal = estItem.sub_total;
                t.form.discount_type = estItem.discount_type;
                t.form.discount_mount = estItem.discount_mount;
                t.form.discount_total = estItem.discount_total;
                t.form.discount_name = estItem.discount_name;
                t.form.total = estItem.total;
                t.taxShow = (dataR.taxShow!=null && dataR.taxShow.meta_value==1) ? 1 : 0;
                t.type_estimate = (dataR.type_estimate!=null && dataR.type_estimate.meta_value=='pro') ? 'pro' : 'normal';
                if(t.type_estimate == 'normal' && t.taxShow==0){t.taxShow=1;} // sino es un estpro, siempre activar taxes
                t.groupShow = (dataR.groupShow!=null && dataR.groupShow.meta_value==1) ? 1 : 0;

                t.selectCTemplate.description = estItem.terms_conditions_desc;
                t.selectCTemplate.title = estItem.terms_conditions_title;
                t.FormSignature.dateCustomer = new Date(estItem.signaturecustomerdate);
                t.FormSignature.dateCompany = new Date(estItem.signaturecompanydate);
                t.itemsprod = response.data.itemsprod; // para enviar al componente
                if (state == 2) {
                    t.form.actionNname = "duplicate"
                    t.form.accepted_by = "";
                    t.form.accepted_date = "";
                    t.form.status = 1;
                    t.FormSignature.dateCustomer = new Date();
                    t.FormSignature.dateCompany = new Date();
                    t.FormSignature.nameCompany = localStorage.getItem("nomCompany");
                }
                else{
                    // si editar, creamos copia de datos
                    localStorage.removeItem("dataOrigin"); // removemos datos antiguos
                    setTimeout(() => {
                        // tiempo de espera para q los datos se ajusten antes de almacenar
                        localStorage.setItem("dataOrigin", JSON.stringify(t.createDataSend()))
                    }, 600);
                }
                t.showLoading = false;
                t.loadEstByID = true;
                // console.log('EstimateById')
            })
            .catch((error) => {
                console.log(error);
            });
        },
        deleteEstimate(idest) {
            let t = this;
            t.$swal({
                title: "Delete t Estimate?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    t.showLoading = true;
                    window.billing
                    .put("estimate/delete/" + idest, {
                        user_id: window.localuserdata,
                    })
                    .then((response) => {
                        t.showLoading = false;
                        if (response.data.status) {
                            t.$notify({
                                group: "noti",
                                title: "Deleted!",
                                type: "success",
                                text: "Deleted Estimate Successful",
                            });
                            t.$router.push({ name: t.rutaPrincipal });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        t.showLoading = false;
                    });
                }
            });
        },
        msjAlert(msj){
            this.$notify({
                group: "noti",
                title: "Error",
                type: "error",
                text: msj
            });
        },
        // abrir pop de add new customer
        popAddCustomer() {
            let t = this;
            this.m = {
                show: true,
                component: 'formCustomer',
                data: {}
            }
            this.$store.commit('core/setOpenModal', this.m);
        },
        isLoadingCustomer(status) {
            let t = this;
            // console.log('isLoadingCustomer', status)
            t.isLoadCustomer = status
        },
        changeCustomer(c) {
            let t = this;
            t.form.customer_id = c.selected.value
            t.form.customer_name = c.selected.text
            t.form.customer_email = c.selected.email
            t.form.customer_address = c.selected.customer_address
            // console.log('changeCustomer form.customer_id', t.form.customer_id)
        },
        updateJobInfo(d){
            let t= this;
            // console.log('updateJobInfo', d)
            t.form.estimates_optional = d.document_name
            t.form.job_information = d.job_information
        },
        updateDateStatus(d){
            let t = this;
            // console.log('updateDateStatus', d)
            t.form.status = d.status;
            t.form.date_created = d.date_created;
        },
        addNewProducts(d) {
            let t = this;
            t.m = {
                show: true,
                component: 'formProducts',
                data: d
            }
            t.$store.commit('core/setOpenModal', t.m);
        },
        setProd(d){
            let t = this;
            // console.log('setProd', d)
            t.itemProducts = d.itemProducts;
            t.taxShow = d.taxShow;
            t.groupShow = d.groupShow;
            t.taxesResumen = d.taxesResumen;
        },
        setMessage(customer_message){
            let t = this;
            console.log('setMessage', customer_message)
            t.form.customer_message = customer_message;
        },
        setAlert(msj){
            let t = this;
            // console.log('setAlertError', msj)
            t.msjAlert(msj);
        },
        setTotales(d){
            let t = this;
            // console.log('setTotales', d)
            t.form.subtotal = d.subtotal;
            t.form.discount_type = d.discount_type;
            t.form.discount_mount = d.discount_mount;
            t.form.discount_total = d.discount_total;
            t.form.discount_name = d.discount_name;
            t.form.totaltax = d.totaltax;
            t.form.total = d.total;
            // console.log('setTotales', t.form)
        },
        setContract(d){
            let t = this;
            // console.log('setContract', d)
            t.form.signe = d.signe;
            t.form.customer_name = d.customer_name;
            t.selectCTemplate.title = d.selectCTemplate.title;
            t.selectCTemplate.description = d.selectCTemplate.description;
            t.FormSignature.dateCustomer = d.FormSignature.dateCustomer;
            t.FormSignature.dateCompany = d.FormSignature.dateCompany;
            t.FormSignature.nameCompany = d.FormSignature.nameCompany;
        },
    },
    watch: {
        validate_load: function(){
            let t = this;
        },
        'form.customer_id': function(){
            let t =this;
            if(t.form.customer_id>0){
                t.saveDisabled=false
                t.disabledSteps.customer=false;
                // console.log('t.form.customer_id', t.form.customer_id)
            }
            else{
                t.saveDisabled=true;
                t.disabledSteps.customer=true;
            }
        },
        'getActionRefresh': function() {
            let t =this;
            console.log('getActionRefresh', t.getActionRefresh)
            if (Object.keys(t.getActionRefresh.data).length > 0) {
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                };
            }
        },
        'form.subtotal': function(){
        },
        getPlanId() {
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal", getActionRefresh: "getActionRefresh" }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
    },
};
</script>
<style lang="scss" scoped>
</style>
