<template>
    <div class="col-lg-5 col-md-6">
        <div class="form-group">
            <label for="customerInput" style="position: relative;width: 100%;display: block;">
                Customer
                <span style="position: absolute;right: 0;top: 3px;">
                    <button type="button" @click="$emit('addNewCustomer')" class="btnlink">
                        <i class="fas fa-plus"></i>
                        New Customer
                    </button>
                </span>
            </label>
            <model-select id="customerInput" class="customerInput" ref="customerInput" :options="CustomersList"
                v-model="selectedCustomer" placeholder="Choose a Customer" tabindex="1" required="true">
            </model-select>
        </div>
        <div class="form-group">
            <div class="form-group bill_to">
                <div v-if="selectedCustomer.value != null && selectedCustomer.value != 0">
                    <div>
                        <span style="display: none;">ID: {{selectedCustomer.value}}</span>
                        <span>
                            {{ selectedCustomer.customer_name }}
                        </span>
                        <span>
                            {{ selectedCustomer.customer_lastname }}
                        </span>
                    </div>
                    <div
                        v-if="selectedCustomer.bussinesname != null && selectedCustomer.bussinesname != '' && selectedCustomer.bussinesname != 'null'">
                        {{ selectedCustomer.bussinesname }}
                    </div>
                    <div v-if="selectedCustomer.billing_address != '' && selectedCustomer.billing_address != null">
                        {{ selectedCustomer.billing_address }}
                    </div>
                    <div v-if="selectedCustomer.city != null ||
                        selectedCustomer.state != null ||
                        selectedCustomer.code != null
                    ">
                        <span v-if="selectedCustomer.city != null"
                            style="margin-right:5px;">{{ selectedCustomer.city }}</span>
                        <span v-if="selectedCustomer.state != null" style="margin-right:5px;">{{ selectedCustomer.state
                            }}</span>
                        <span v-if="selectedCustomer.code != null" style="margin-right:5px;">{{ selectedCustomer.code
                            }}</span>
                    </div>
                    <div>
                        <span v-if="selectedCustomer.customer_phone != null">
                            {{ selectedCustomer.customer_phone }}
                        </span>
                        <span v-if="selectedCustomer.customer_officephone != null">
                            <span v-if="selectedCustomer.customer_phone != null"> / </span>
                            {{ selectedCustomer.customer_officephone }}
                        </span>
                    </div>
                    <div v-if="selectedCustomer.email != null">
                        {{ selectedCustomer.email }}
                    </div>
                </div>
                <label v-else for="customerInput" style="position: relative;width: 100%;display: block;color:#dee2e6;">
                    Name, Last name<br>
                    Company name<br>
                    Billing Address<br>
                    City, Estate, Zip Code<br>
                    Cell Phone / Office Phone<br>
                    Email Address
                </label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ModelSelect } from 'vue-search-select';
export default {
    components: {
        ModelSelect,
    },
    props: [
        "controlLoad",
        "propForm"
    ],
    data(){
        return {
            // CustomersList: this.propCustomersList,
            // selectedCustomer: this.propSelectedCustomer,
            form: this.propForm,
            CustomersList: [],
            selectedCustomer: {
                value: 0,
                text: null,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_lastname: null,
                customer_phone: null,
                customer_officephone: null,
                email: null,
                city: null,
                state: null,
                code: null,
            },
        };
    },
    created() {
        let t = this;
        // console.log('created customer_id', t.form.customer_id);
        t.init();
    },
    watch: {
        "form.customer_id": function(){
            let t = this;
            // console.log('watch customer_id', t.form.customer_id);
        },
        controlLoad: function(){
            let t = this;
            if(this.controlLoad){
                t.init();
            }
        },
        'selectedCustomer.value': function(){
            let t =this;
                // console.log('form.customer_id', t.selectedCustomer.value)
                t.form.customer_id = t.selectedCustomer.value
                t.form.customer_name = t.selectedCustomer.customer_name
                t.form.customer_email = t.selectedCustomer.email
                t.form.customer_address = t.selectedCustomer.customer_address
            t.updateProps();
        },
        'getActionRefresh': function() {
            let t =this;
            if (Object.keys(t.getActionRefresh.data).length > 0) {
                let data = t.getActionRefresh.data; 
                let methods = t.getActionRefresh.action;
                if (methods === 'newCustomer') {
                    t.selectedCustomer = data;
                    t.CustomersList.push(data);
                    localStorage.setItem('CustomersList', JSON.stringify(t.CustomersList));
                    // console.log('getActionRefresh newCustomer', t.selectedCustomer)
                    // localStorage.removeItem('CustomersList');
                    // t.getCustomer();
                }
            }
        },
    },
    methods: {
        init(){
            let t = this;
            // console.log('customer_id', t.form.customer_id);
            t.getCustomer(parseInt(t.form.customer_id)).then((resulcustomers) => {
                // console.log('continue');
                let params = Object.keys(t.$route.params);
                if (t.form.actionNname == "create") {
                    // cuando sea create abrir el pop
                    if(t.$route.name== 'estproForm' || t.$route.name=='InvoicesNew'){
                        t.selectedCustomer.value = 0;
                        t.$refs.customerInput.openOptions();
                    }
                }
            });
        },
        getCustomer(idc) {
            let t = this;
            return new Promise((resolve, reject) => {
                t.$emit('loadCustomer', 'pending');
                if(localStorage.getItem('CustomersList')==null || (idc !== undefined && idc !== null)){
                    if(!t.selectCustomerById(idc)){
                        localStorage.removeItem('CustomersList');
                        window.billing
                        .get("customers/" + window.localuserdata)
                        .then((response) => {
                            const Customers = [];
                            // console.log('getCustomers', response.data.result);
                            let result = response.data.result;
                            if (result) {
                                for (let item in result) {
                                    var id = result[item].id;
                                    var text = result[item].firstname;
                                    var billing_address =
                                        result[item].billingaddress;
                                    var customer_address = result[item].address;
                                    var bussinesname = result[item].bussinesname;
                                    var email = result[item].email;
                                    var lastname = result[item].lastname;
                                    var customer_phone = result[item].mobilephone;
                                    var customer_officephone = result[item].officephone;
                                    var city = result[item].bcity;
                                    var state = result[item].bstate;
                                    var code = result[item].bpostcode;
                                    city = city != null ? city + "," : "";
                                    state = state != null ? state : "";
                                    code = code != null ? code : "";
                                    var guion = " - ";
                                    t.form.customer_email = email;
                                    t.form.customer_id = id;
                                    // console.log('t.form.customer_id', t.form.customer_id);
                                    if (bussinesname == null) {bussinesname = "";guion = " ";}
                                    if (lastname == null) {lastname = "";}
                                    let newCustomer ={
                                        text: text + " " + lastname + guion + bussinesname,
                                        id: id,
                                        value: id,
                                        billing_address: billing_address,
                                        bussinesname: bussinesname,
                                        customer_address: customer_address,
                                        customer_name: text,
                                        customer_lastname: lastname,
                                        customer_phone: customer_phone,
                                        customer_officephone: customer_officephone,
                                        email: email,
                                        city: city,
                                        state: state,
                                        code: code,
                                    };
                                    Customers.push(newCustomer);
                                    if(idc==id){
                                        // console.log('get customer id', newCustomer)
                                    }
                                }
                            }
                            t.CustomersList = Customers;
                            localStorage.setItem('CustomersList', JSON.stringify(t.CustomersList));
                            t.$emit('sDS', 'CustomersDate');
                            if(idc>0) {
                                t.selectCustomerById(idc);
                            }
                            t.$emit('loadCustomer', 'finish');
                            resolve(true);
                        })
                        .catch((error) => {
                            console.log(error);
                            t.$emit('loadCustomer', 'error');
                            reject(error);
                        });
                    }
                }else{
                    t.CustomersList = JSON.parse(localStorage.getItem('CustomersList'));
                    t.$emit('loadCustomer', 'finish');
                    if(localStorage.getItem('CustomersDate')==null){
                        t.$emit('sDS', 'CustomersDate');
                    }
                    resolve(true);
                }
            });
        },
        selectCustomerById(id) {
            let t = this;
            if(id!=null && id>0){
                // console.log('t.CustomersList', t.CustomersList)
                const customer = t.CustomersList.find(c => c.id === id);
                if (customer) {
                    t.selectedCustomer = {
                        value: id,
                        text: customer.text,
                        billing_address: customer.billing_address,
                        bussinesname: customer.bussinesname,
                        customer_address: customer.customer_address,
                        customer_name: customer.customer_name,
                        customer_lastname: customer.customer_lastname,
                        customer_phone: customer.customer_phone,
                        customer_officephone: customer.customer_officephone,
                        email: customer.email,
                        city: customer.city,
                        state: customer.state,
                        code: customer.code,
                    };
                    // console.log("Cliente encontrado", t.selectedCustomer);
                    return true;
                } else {
                    // console.log("Cliente no encontrado", id);
                    return false;
                }
            }else {
                    // console.log("iniciando como vacio", id);
                    return false;
                }
        },
        updateProps(){
            let t = this;
            t.$emit('updateProps', {
                "list": t.CustomersList,
                "selected": t.selectedCustomer,
            });
        },
    },
    computed: {
        ...mapGetters("core", { getActionRefresh: "getActionRefresh" }),
    }
};
</script>
